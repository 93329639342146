var render = function render(){
  var _vm$question, _vm$question$reply, _vm$question2, _vm$question2$reply;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board__view mb-20 mb-lg-40"
  }, [_c('div', {
    staticClass: "board__view__row"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.question.keyword))])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.question.subject) + " ")])])], 1)], 1), _c('div', {
    staticClass: "board__view__row"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-center"
  }, [_c('div', {
    staticClass: "d-flex align-center mr-8 mr-lg-16"
  }, [_c('i', {
    staticClass: "icon icon-clock mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs(_vm.question.createdAt).format("YYYY.MM.DD")) + " ")])]), _c('div', {
    staticClass: "d-flex align-center",
    class: {
      'justify-end': _vm.justifyEnd,
      'justify-center': !_vm.justifyEnd
    }
  }, [_c('i', {
    staticClass: "icon icon-view mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.question.viewCount.format()) + " ")])])])]), _c('div', {
    staticClass: "board__view__row min-h-160px"
  }, [_c('p', {
    staticClass: "page-text grey-6--text line-height-15"
  }, [_vm._v(" " + _vm._s(_vm.question.content) + " ")])]), _vm.question.reply.content ? _c('div', {
    staticClass: "board__view__row grey-fa"
  }, [_c('div', {
    staticClass: "mb-4 mb-lg-8"
  }, [_c('v-row', {
    staticClass: "row--xx-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticStyle: {
      "border-radius": "6px"
    },
    attrs: {
      "color": "#FFEEE3"
    }
  }, [_c('span', {
    staticClass: "primary--text font-weight-medium"
  }, [_vm._v("전문가 답변")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-user-level3"
  })]), _c('v-col', [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$question = _vm.question) === null || _vm$question === void 0 ? void 0 : (_vm$question$reply = _vm$question.reply) === null || _vm$question$reply === void 0 ? void 0 : _vm$question$reply.name) + " ")])])], 1)], 1), _c('p', {
    staticClass: "page-text grey-6--text line-height-15",
    domProps: {
      "innerHTML": _vm._s((_vm$question2 = _vm.question) === null || _vm$question2 === void 0 ? void 0 : (_vm$question2$reply = _vm$question2.reply) === null || _vm$question2$reply === void 0 ? void 0 : _vm$question2$reply.content)
    }
  })]) : _vm._e()]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1), _c('v-spacer'), !_vm.question.reply.content ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "color": "grey-6",
      "to": `/information/qna/${_vm.question._id}/edit`
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14"
  }, [_vm._v("수정")])])], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }