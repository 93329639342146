<template>
    <div>
        <div class="board__view mb-20 mb-lg-40">
            <div class="board__view__row">
                <v-row class="row--x-small" align="center">
                    <v-col cols="auto">
                        <v-chip color="primary-50" text-color="primary">{{ question.keyword }}</v-chip>
                    </v-col>
                    <v-col>
                        <p class="page-text font-weight-medium">
                            {{ question.subject }}
                        </p>
                    </v-col>
                </v-row>
            </div>
            <div class="board__view__row">
                <div class="d-flex flex-wrap align-center">
                    <div class="d-flex align-center mr-8 mr-lg-16">
                        <i class="icon icon-clock mr-4 mr-lg-8"></i>
                        <p class="page-text page-text--sm grey-9--text">
                            {{ $dayjs(question.createdAt).format("YYYY.MM.DD") }}
                        </p>
                    </div>
                    <div class="d-flex align-center" :class="{ 'justify-end': justifyEnd, 'justify-center': !justifyEnd }">
                        <i class="icon icon-view mr-4 mr-lg-8"></i>
                        <p class="page-text--sm grey-9--text">
                            {{ question.viewCount.format() }}
                        </p>
                    </div>
                </div> 
            </div>
            <div class="board__view__row min-h-160px">
                <p class="page-text grey-6--text line-height-15">
                    {{ question.content }}
                </p>
            </div>
            <div v-if="question.reply.content" class="board__view__row grey-fa">
                <div class="mb-4 mb-lg-8">
                    <v-row class="row--xx-small" align="center">
                        <v-col cols="auto">
                            <v-chip color="#FFEEE3" style="border-radius: 6px">
                                <span class="primary--text font-weight-medium">전문가 답변</span>
                            </v-chip>
                        </v-col>
                        <v-col cols="auto">
                            <i class="icon icon-user-level3"></i>
                        </v-col>
                        <v-col>
                            <p class="page-text font-weight-medium">
                                {{ question?.reply?.name }}
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <p v-html="question?.reply?.content" class="page-text grey-6--text line-height-15"></p>
            </div>
        </div>
        <v-row>
            <v-col cols="auto">
                <v-btn @click="$router.go(-1)" outlined color="primary" class="h-lg-40px">리스트</v-btn>
            </v-col>
            <v-spacer />
            <v-col v-if="!question.reply.content" cols="auto">
                <v-row class="row--x-small">
                    <v-col cols="auto">
                        <v-btn color="grey-6" class="h-lg-40px" :to="`/information/qna/${question._id}/edit`">
                            <span class="white--text font-size-14">수정</span>
                        </v-btn>
                    </v-col>
                    <!-- <v-col cols="auto">
                        <v-btn outlined color="grey-b3" class="h-lg-40px">
                            <span class="grey-6--text font-size-14">삭제</span>
                        </v-btn>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '@/api'
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";

export default {
    components: {
        BoardTit,
        BoardCountView,
    },
    props: {
        question: { type: Object, default: {} }
    }
};
</script>

<style lang="scss" scoped>
</style>